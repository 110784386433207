import dotsMore from "../../assets/dotsMore.png";
import React, {useState} from "react";
import { Modal, Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ModalBody, ModalFooter} from "react-bootstrap";
import { faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconpdf from "../../assets/pdficon.png";
import WordIcon from "../../assets/wordIcon";
import iconexcel from "../../assets/excelicon.png";
import iconpower from "../../assets/powericon.png";
import iconrarzip from "../../assets/iconrarzip.png";
import icontxt from "../../assets/txticon.png";
import iconnopreview from "../../assets/nopreview.svg";
import StatusBadge from "../Officer/StatusBadge";
import AzurePostOptions from "../../v2/components/AzurePost/AzurePost.Options";
import AzureProfileCard from "../../v2/components/AzureProfile/AzureProfileCard";
import { UsedAppNBCContext } from "../../v2/AppNBC.Provider";

library.add(faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown);

const IncidentCard = (props) =>
{
    const appNBCContext = UsedAppNBCContext();

    const [imageModal, setImageModal] = useState(false);
    const [selectedImagePreview, setImagePreview] = useState(null);

    const toggleModalImage = () => {
        setImageModal(false);
    }

    const downloadFile = (item) => {
        const url = `data:${item.fileType};base64,${item.fileBase64String}`;
        const a = document.createElement("a");
        a.href = url;

        a.download = item.fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const PreviewImageOnly = (imgprev) => {
        setImageModal(true);
        setImagePreview(imgprev);
    }

    const renderSwitchImageBinaryDataPreview = (item, mode) => {
        let _className = "";
        switch (mode) {
            case 1:
                _className = "card-img-top";
                break;
            case 2:
                _className = "thumbimg";
                break;
            default:
                _className = "card-img-top";
                break;
        }

        return <img className={_className}
                    key={item.id} 
                    aria-hidden 
                    alt="IMAGE" 
                    onClick={() => PreviewImageOnly(item)} 
                    src={`data:image/jpeg;base64,${item.fileBase64String}`} 
                    style={{cursor: "pointer"}}  />;
    }

    const PreviewImage = (imgprev) => {
        if (imgprev.file.type.includes("image/")) {
            setImageModal(true);
            setImagePreview(imgprev);
        }
        else  {
            window.open(imgprev.file.path);
        }
    }

    const renderSwitchFileBinaryData = (item,mode) => {
        let _className = "";
        switch (mode) {
            case 1:
                _className = "card-img-top";
                break;
            case 2:
                _className = "thumbimg";
                break;
            default:
                _className = "card-img-top";
                break;
        }

        switch (item.fileType) {
            case "application/pdf": 
                return <img className={_className} 
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}} 
                            src={iconpdf} 
                            aria-hidden 
                            alt="PDF" 
                            style={{cursor: "pointer"}}  />;
                            
            case "officedocument.word":
                return <img className={_className} 
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}}
                            src={WordIcon} 
                            aria-hidden 
                            alt="WORD" 
                            style={{cursor: "pointer"}}  />;

            case "officedocument.spreadsheetml":
                return <img className={_className}  
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}} 
                            src={iconexcel} 
                            aria-hidden 
                            alt="EXCEL" 
                            style={{cursor: "pointer"}}  />;
            
            case "officedocument.presentationm":
                return <img className={_className} 
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}} 
                            src={iconpower} 
                            aria-hidden 
                            alt="POWER POINT" 
                            style={{cursor: "pointer"}}  />;
        
            case "text/plain":
                return <img className={_className} 
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}} 
                            src={icontxt} 
                            aria-hidden 
                            alt="TEXT" 
                            style={{cursor: "pointer"}}  />;
    
            case "application/x-zip-compressed":
                return <img className={_className} 
                            onClick={(e) =>  {e.preventDefault(); downloadFile(item);}} 
                            src={iconrarzip} 
                            aria-hidden 
                            alt="ZIP" 
                            style={{cursor: "pointer"}}  />;
            default:
                return <img width={"250px"} src={iconnopreview} aria-hidden alt="FILE" />;
        }
    }

    const renderSwitchFile = (item, mode) => {
        let _className = "";
        switch (mode) {
            case 1:
                _className = "card-img-top";
                break;
            case 2:
                _className = "thumbimg";
                break;
            default:
                _className = "card-img-top";
                break;
        }

        switch (item.fileType) {
            case "application/pdf": 
                return <img className={_className}
                            key={item.id}
                            onClick={() => PreviewImage(item)}
                            src={iconpdf} 
                            aria-hidden 
                            alt="PDF" 
                            style={{cursor: "pointer"}}  />;
                            
            case "officedocument.word":
                return <img className={_className}
                            key={item.id}  
                            onClick={() => PreviewImage(item)}
                            src={WordIcon} 
                            aria-hidden 
                            alt="WORD" 
                            style={{cursor: "pointer"}}  />;

            case "officedocument.spreadsheetml":
                return <img className={_className}
                            key={item.id} 
                            onClick={() => PreviewImage(item)}
                            src={iconexcel} 
                            aria-hidden 
                            alt="EXCEL" 
                            style={{cursor: "pointer"}}  />;
            
            case "officedocument.presentationm":
                return <img className={_className}
                            key={item.id} 
                            onClick={() => PreviewImage(item)}
                            src={iconpower} 
                            aria-hidden 
                            alt="POWER POINT" 
                            style={{cursor: "pointer"}}  />;
        
            case "text/plain":
                return <img className={_className}
                            key={item.id} 
                            onClick={() => PreviewImage(item)}
                            src={icontxt} 
                            aria-hidden 
                            alt="TEXT" 
                            style={{cursor: "pointer"}}  />;
    
            case "application/x-zip-compressed":
                return <img className={_className}
                            key={item.id} 
                            onClick={() => PreviewImage(item)}
                            src={iconrarzip} 
                            aria-hidden 
                            alt="ZIP" 
                            style={{cursor: "pointer"}}  />;
            default:
                return <img width={"250px"} src={iconnopreview} aria-hidden alt="FILE" />;
        }
    }

    // const renderSwitchImage = useCallback((item) => {
    //     if(appNBCContext.isFileEncryptionEnable) {
    //         if(item.fileBase64String !== "") {
    //             return <img key={item.id} className="card-img-top" 
    //                         src={`data:image/jpeg;base64,${item.fileBase64String}`} 
    //                         aria-hidden alt="Card image cap" />
    //         } else {
    //             return <img  src={iconnopreview} aria-hidden alt="Card image cap" />
    //         }
           
    //     } else {
    //         if(item.url !== "") {
    //             return <img key={item.id} className="card-img-top" 
    //                         src={item.url} 
    //                         aria-hidden alt="Card image cap" />
    //         } else {
    //             return <img  src={iconnopreview} aria-hidden alt="Card image cap" />
    //         }
           
    //     }
    // },[appNBCContext])

    // const renderSwitchFileIcon = (item) => {
    //     switch (item.fileType) {
    //         case "application/pdf": 
    //             return iconpdf;
    //         case "officedocument.word":
    //             return WordIcon;
    //         case "officedocument.spreadsheetml":
    //             return iconexcel;
    //         case "officedocument.presentationm":
    //             return iconpower;
    //         case "text/plain":
    //          return   icontxt;
    //         case "application/x-zip-compressed":
    //             return iconrarzip;
    //         default:
    //             return  iconnopreview;
    //     }
    // }

    const PrimaryFontFamily = "Work Sans";
    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
            <p className="text" style={{fontFamily: PrimaryFontFamily, fontWeight: 500, fontSize: "16px", paddingRight: "30px"}}>
                {isReadMore ? text.slice(0, 200) : text} {"  "}
                { text.length > 200 ? 
                    ( <span onClick={toggleReadMore} className="read-or-hide" style={{color: "#002542", cursor: "pointer", fontSize: "16px", fontWeight: 400, textDecoration: "underline",fontFamily: PrimaryFontFamily}}>
                        {isReadMore ? "Read More..." : "Show Less..."}
                    </span>) 
                    : (<></>)
                }
            </p>
        );
    };

    return <>
        <div key={props.item.id} className="row">
            <div className="col-12">
                <div key={props.item.id} className="card cardpost">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="container-img" style={{paddingLeft: "20px"}}>

                                {props.item.azureIncidentImages !== undefined && props.item.azureIncidentImages.length > 0  
                                    ?  (
                                        appNBCContext.isFileEncryptionEnable ? 
                                        (<>{renderSwitchImageBinaryDataPreview(props.item.azureIncidentImages[0],1)}</>) 
                                        : 
                                        (<img onClick={() => PreviewImageOnly(props.item.azureIncidentImages[0])} 
                                                                                    key={props.item.azureIncidentImages[0].id} 
                                                                                    src={props.item.azureIncidentImages[0].url}
                                                                                    aria-hidden 
                                                                                    className="card-img-top" 
                                                                                    alt="Card image cap" />)
                                    )
                                    : 
                                    (
                                        props.item.azureIncidentFiles !== undefined && props.item.azureIncidentFiles.length > 0 ? 
                                        (
                                            <>
                                                <div className="container-img">
                                                    {appNBCContext.isFileEncryptionEnable 
                                                        ? 
                                                        (<>{renderSwitchFileBinaryData(props.item.azureIncidentFiles[0],1)}</>)
                                                        : 
                                                        (<>{renderSwitchFile(props.item.azureIncidentFiles[0],1)}</>)
                                                    }
                                                </div>
                                            </>
                                        ) 
                                        : 
                                        (<>
                                            <div className="container-img" style={{marginLeft: "10px"}}>
                                                <img  src={iconnopreview} aria-hidden alt="Card image cap" />
                                            </div>
                                        </>)
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-9 marginPost">
                            <div className="row">
                                <div className="col-11">
                                    <label className="textBlue" >{props.item.agencyName}</label>
                                </div>
                                <div className="col-1">
                                    <button id={`${props.Key}${props.item.id}`} className="btn btn-default btnMore" style={{border: "none"}}><img src={dotsMore} alt="more" /></button>
                                    <UncontrolledPopover trigger="legacy" placement="bottom" target={`${props.Key}${props.item.id}`} >
                                        <PopoverBody>
                                            <AzurePostOptions DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents} OpenFolders={props.OpenFolders} data={props.item} OpenSharePost={(event) => props.OpenSharePost(props.item)} OpenReportPost={(event) => props.OpenReportPost(props.item)} SetNotification={props.SetNotification} ></AzurePostOptions>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </div>
                            <div className="row">
                                <label className="incidenTitle">Incident: {props.item.incidentNum}</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FontAwesomeIcon className="svgIconGrey" icon={faClock} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.date2}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faLocationDot} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.address}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faFolderOpen} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.type}
                                    </span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ReadMore>
                                        {props.item.description}
                                    </ReadMore>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        props.item.azureIncidentImages && props.item.azureIncidentImages.length > 1 ? 
                                        (
                                            appNBCContext.isFileEncryptionEnable ? 
                                            (
                                                props.item.azureIncidentImages.slice(1).map((file) => {
                                                    return <>
                                                        <div className="thumbNailImageIncident">
                                                            <> {renderSwitchImageBinaryDataPreview(file,2)}</>
                                                        </div>
                                                    </>
                                                })
                                            ) 
                                            : 
                                            ( props.item.azureIncidentImages.slice(1).map((file) => {
                                                return <>
                                                    <div className="thumbNailImageIncident">
                                                        <img key={file.id} 
                                                            className="thumbimg" 
                                                            onClick={() => PreviewImage(file)} 
                                                            src={file.url} 
                                                            aria-hidden alt="Card image cap" />
                                                    </div>
                                                </>
                                            })
                                            )
                                        ) 
                                        : 
                                        (<></>)
                                    }

                                    {
                                        props.item.azureIncidentFiles && props.item.azureIncidentFiles.length > 1 ? 
                                        (appNBCContext.isFileEncryptionEnable ? 
                                            (
                                                props.item.azureIncidentFiles.slice(1).map((file) => {
                                                    return <>
                                                        <div className="thumbNailImageIncident">
                                                            {renderSwitchFileBinaryData(file,2)}
                                                        </div>
                                                    </>
                                                })
                                            ) 
                                            : 
                                            (
                                                props.item.azureIncidentFiles.slice(1).map((file) => {
                                                    return <>
                                                        <div className="thumbNailImageIncident">
                                                            {renderSwitchFile(file,2)}
                                                        </div>
                                                    </>
                                                })
                                            ) 
                                        ) 
                                        : 
                                        (<></>)
                                    }
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-11">
                                    {
                                        props.item.myPost && props.item.myPost === true &&
                                        <>
                                            <label className="lblProfile">My Posts</label>
                                        </>
                                    }

                                    {
                                        props.item.myPost === false &&
                                        <>
                                            <label className="cardSpan">
                                                {props.item.division ? props.item.division + ":" : ""}&nbsp;
                                            </label>

                                            <label className="lblProfile" id={`PopoverLegacy${props.item.id}`} >
                                                {props.item.userName} &nbsp; {StatusBadge(props.item.user.deleted ? 3 : props.item.user.appStatus === 1 ? 2 : 1 )} &nbsp;<FontAwesomeIcon className="svgIconGrey" icon={faAngleDown} />
                                            </label>

                                            <UncontrolledPopover target={`PopoverLegacy${props.item.id}`}
                                                trigger="legacy"
                                                placement="top-start"
                                            >

                                                <PopoverBody>
                                                    <AzureProfileCard Incident={props.item} openMessage={props.openMessage} id={props.item.userId} ></AzureProfileCard>
                                                </PopoverBody>

                                            </UncontrolledPopover>
                                        </>
                                    }

                                </div>


                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
        <Modal size="lg" isOpen={imageModal}>
                <ModalBody className="PreviewImage">
                    {appNBCContext.isFileEncryptionEnable 
                        ? 
                        (<>
                            {selectedImagePreview &&
                                <img width="100%" height="100%"
                                    onClick={(e) =>  {e.preventDefault(); downloadFile(selectedImagePreview);}} 
                                    src={`data:image/jpeg;base64,${selectedImagePreview.fileBase64String}`} 
                                    aria-hidden 
                                    alt="IMAGE" 
                                    style={{cursor: "pointer"}}  />
                            }
                        </>) 
                        : 
                        (<>
                            {selectedImagePreview &&
                                <img src={selectedImagePreview.url} width="100%" height="100%" alt={selectedImagePreview.fileName || "" } />
                            }
                        </>)
                    }
                    
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-secondary btn-cancel-modal" onClick={toggleModalImage}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
    </>

}

export default IncidentCard;